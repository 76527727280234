import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { startTransition, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { cacheAssets } from 'remix-utils/cache-assets'

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	beforeSend(event) {
		if (event.request?.url) {
			const url = new URL(event.request.url)
			if (
				url.protocol === 'chrome-extension:' ||
				url.protocol === 'moz-extension:'
			) {
				// This error is from a browser extension, ignore it
				return null
			}
		}
		return event
	},
	denyUrls: [
		/\/build\//,
		/\/favicon.ico\//,
		/\/fonts\//,
		/\/images\//,
		'localhost',
	],
	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
		Sentry.replayIntegration(),
		Sentry.browserProfilingIntegration(),
	],
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	profilesSampleRate: 1,
})

// See: https://github.com/sergiodxa/remix-utils/tree/25b658f723c7ede1b95f7f2f72a0360962360d09?tab=readme-ov-file#cacheassets
cacheAssets({ cacheName: 'atomicLoreAssets', buildPath: '/build/' }).catch(
	error => {
		console.error(error)
	},
)

startTransition(() => {
	clearBrowserExtensionInjectionsBeforeHydration()
	hydrateRoot(document, <RemixBrowser />)
})

function clearBrowserExtensionInjectionsBeforeHydration() {
	document
		.querySelectorAll(
			[
				'html > *:not(body, head)',
				'script[src*="extension://"]',
				'link[href*="extension://"]',
			].join(', '),
		)
		.forEach(s => {
			s.parentNode?.removeChild(s)
		})

	const $targets = {
		html: {
			$elm: document.querySelector('html')!,
			allowedAttributes: ['lang', 'dir', 'class'],
		},
		head: {
			$elm: document.querySelector('head')!,
			allowedAttributes: [''],
		},
		body: {
			$elm: document.querySelector('body')!,
			allowedAttributes: ['class'],
		},
	}

	Object.entries($targets).forEach(([targetName, target]) => {
		target.$elm.getAttributeNames().forEach(attr => {
			if (!target.allowedAttributes.includes(attr)) {
				target.$elm.removeAttribute(attr)
			}
		})
	})
}
